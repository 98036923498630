import { CloudUpload } from '@mui/icons-material';
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, MenuItem, Radio, RadioGroup, TextField } from '@mui/material';
import React from 'react'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function DynamicForms(props) {
    const { type, question, required, option, answer, handleChange, index, handleActive, handleChangeDate, handleChangeCheckbox } = props;

    const handleRowRadio = () => {
      const arrayOfNumbers = []
      const total = Number(option.rangeTo) || 5
      for (let index = Number(option.rangeFrom || 0); index < total; index++) {
        arrayOfNumbers.push(index)
      }
  
      return arrayOfNumbers
    }
  return (
    <div className="py-2">
      {type === "text" && (
        <TextField
          label={question}
          required={required}
          value={answer}
          fullWidth
          variant='outlined'
          size='small'
          onChange={(e) => handleChange(e, index)}
        />
      )}
      {type === "date" && (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker 
            margin="normal"
            id="date-picker-dialog"
            label={question}
            format="MM/dd/yyyy"
            inputVariant='outlined'
            value={answer || null}
            onChange={(data) => handleChangeDate(data, index)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            />
        {/* <DateTimePicker value={answer || null} onChange={(data) => handleChangeDate(data, index)} /> */}
      </LocalizationProvider>
        // <TextField
        //   label={question}
        //   required={required}
        //   value={answer}
        //   type="date"
        //   fullWidth
        //   variant='outlined'
        //   size="small"
        //   onChange={(e) => handleChange(e, index)}
        // />
      )}
      {type === "textarea" && (
        <TextField
          label={question}
          required={required}
          multiline
          minRows={4}
          value={answer}
          fullWidth
          variant='outlined'
          size="small"
          onChange={(e) => handleChange(e, index)}
        />
      )}
      {type === "checkbox" && (
        <FormGroup onChange={(e) => handleChangeCheckbox(e, index)}>
           <FormLabel id="demo-checkbox-buttons-group-label">{question}</FormLabel>
          {option?.map((item, ind) => (
            <FormControlLabel control={<Checkbox name={item} />} label={item} key={ind} />
          ))}
        </FormGroup>
      )}
      {type === "select" && (
        <TextField
          label={question}
          required={required}
          select
          value={answer}
          fullWidth
          variant='outlined'
          size="small"
          onChange={(e) => handleChange(e, index)}
        >
          {option?.map((item, id) => (
            <MenuItem value={item} key={id}>
              {item}
            </MenuItem>
          ))}
        </TextField>
      )}
      {type === "radio" && (
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">{question}</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            // defaultValue="female"
            name="radio-buttons-group"
            onChange={(e) => handleChange(e, index)}
            value={answer}
          >
            {option?.map((item, ind) => (
              <FormControlLabel value={item} control={<Radio />} label={item} />
            ))}
          </RadioGroup>
        </FormControl>
      )}
      {type === "upload" && (
        <Button
          variant="contained"
          component="label"
          startIcon={<CloudUpload />}
        >
          {question}
          <input hidden accept="image/*" multiple type="file" />
        </Button>
      )}

      {type === 'radioGroup' && (
        <FormControl>
          <FormLabel className="pb-1">{question}</FormLabel>
          <div className="" style={{display: 'flex'}}>
            <div style={{minWidth: '10rem', maxWidth: '35%', textAlign: 'center'}}>
              <p>{option.labelOption}</p>
            </div>
            <div>
            <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            // defaultValue="female"
            // name="radio-buttons-group"
            onChange={(e) => handleChange(e, index)}
            value={Number(answer)}
            row
          >

            {handleRowRadio()?.map((item, ind) => (
              <FormControlLabel labelPlacement="top" value={item} control={<Radio />} label={ind} />
            ))}
          </RadioGroup>

            </div>
          </div>
        </FormControl>
      )}
    </div>
  );
}
