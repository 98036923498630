import logo from './logo.svg';
import './App.css';
import RouteComponent from './RouteComponent';

function App() {
  return (
    // <div className="App">
      <RouteComponent />
    // </div>
  );
}

export default App;
