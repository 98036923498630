import moment from 'moment/moment'
import React, {useState} from 'react'
import { submitFormAnswer } from '../Service'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Button } from '@mui/material';
import DynamicForms from './DynamicForms';
import { useNavigate } from 'react-router-dom';

export default function PublicViewForm({data}) {
    const history = useNavigate()
    const [isLoading, setLoading] = useState(false)
    const [viewMedia, setViewMedia] = useState({
        show: false,
        item: {}
    })
    const [displayForm, setDisplayForm] = React.useState({})
    const [sliderData, setSliderData] = useState([])
    const handleChange = ({ target: { name, value } }, indexOfForm) => {
      const temp = {...displayForm};
      const tempForm = [...temp.schemaDefinition]
      if (temp.schemaDefinition[indexOfForm].type === 'date') {
        value = moment(value).format('YYYY-MM-DD')
      }
      temp.schemaDefinition[indexOfForm].answer = value;
      setDisplayForm(temp);
    };
    const handleChangeCheckbox = ({ target: { name, checked } }, indexOfForm) => {
        const temp = {...displayForm};
        const tempForm = [...temp.schemaDefinition]
        const payload = {
          name,
          checked
        }
        let checkbox = [...temp.schemaDefinition[indexOfForm].answer]
        const objIndex= checkbox.findIndex((el) => el.name === name)
        if (objIndex === -1) {
            checkbox = [...temp.schemaDefinition[indexOfForm].answer, {...payload}]
        } else {
          checkbox[objIndex].checked = checked
        }
        const filteredData = checkbox.filter((el) => el.checked);
  
        temp.schemaDefinition[indexOfForm].answer = filteredData
        setDisplayForm(temp);
      };
      const handleChangeDate = (data, indexOfForm) => {
        const temp = {...displayForm};
        const tempForm = [...temp.schemaDefinition]
        temp.schemaDefinition[indexOfForm].answer = data;
        setDisplayForm(temp);
      };

      React.useEffect(() => {
        if (data) {
            setDisplayForm(data)
            let bannerData = new Set()
            if (data?.banner1Url) {
                bannerData.add(data?.banner1Url)
                setSliderData([...sliderData, data?.banner1Url])
            }
            if (data?.banner2Url) {
                bannerData.add(data?.banner2Url)
            }
            setSliderData([...bannerData])
            // console.log(state, 'state data');
        }
    }, [data])
    const handleSubmitCallback = () => {
        setLoading(false)
        history('/success')
    }
    const handleSubmitCallbackError = () => {
        setLoading(false)
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        const data = displayForm.schemaDefinition?.map((item) => {
            return {
                [item.question]: item.answer
            } 
        })
        const payload = {
            answers: data,
            // legacyUserId: userData?.responseData?.userId
        }

        return submitFormAnswer(displayForm.id, payload, handleSubmitCallback, handleSubmitCallbackError)
    }
    const determineUrlType = (data) => {
        const fileType = data?.split('.').pop()
        // console.log(data.split('.'));

        if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg' || fileType === 'PNG' || fileType === 'JPG' || fileType === 'JPEG') {
            return 'image'
        }
        return 'mp4'
    }

    const handleOpenModal = (item) => {
        setViewMedia({...viewMedia, show: true, item})
    }
    const handleClose = () => {
        setViewMedia({...viewMedia, show: false, item: {}})
    }
  return (
    <div>
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <Carousel
                showThumbs={false}
                showStatus={sliderData.length !== 1}
                showIndicators={sliderData.length !== 1}
            >
                {sliderData?.map((item, index) => (
                <div className='h-full w-full object-cover cursor-pointer'>
                    {determineUrlType(item) === 'image' ? <img src={item} key={index} alt="" srcset="" className='w-6/12 object-cover cursor-pointer h-full px-4' onClick={() => handleOpenModal(item)} /> :
                    <video className='w-6/12 object-cover cursor-pointer h-full' onClick={() => handleOpenModal(item)}>
                    <source src={item} type="video/mp4" />
                    Your browser does not support the video tag.
                    </video>}

                </div>
            ))}
            </Carousel>
        </div>
        <div className='px-8 py-4 flex justify-center'>
            <div>
                <h5 className='pb-1.5 font-bold text-xl'>{data?.name}</h5>
                <p>{data?.description}</p>
            </div>
        </div>
        <div className='flex justify-center pt-8'>
            <form className='w-2/3' onSubmit={handleSubmit}>
                {displayForm?.schemaDefinition?.length > 0 && displayForm?.schemaDefinition?.map((item, index) => <DynamicForms {...item} index={index} handleChange={handleChange} handleChangeDate={handleChangeDate} handleChangeCheckbox={handleChangeCheckbox} />)}

                <div className='flex justify-end pb-8'>
                    {/* <Button variant="outlined" color='primary'>Back</Button> */}
                    <div className='pl-8'>
                        <Button type='submit' variant="contained" disabled={isLoading}>{isLoading ? 'Loading...' : 'Submit'}</Button>

                    </div>
                </div>
            </form>
        </div>
    </div>
  )
}
