import { BrowserRouter as Router, Routes, Route  } from 'react-router-dom';

import React from 'react'
import Forms from './Page/Forms';
import SuccessPage from './Page/SuccessPage';

export default function RouteComponent() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Forms />} />
        <Route path='/success' element={<SuccessPage />} />
      </Routes>
    </Router>
  )
}
