import { Button } from '@mui/material'
import React from 'react'
import '../styles/successPage.css'

export default function SuccessPage() {
  return (
    <div className="success_page_container">
		<h1 className='successPage_title'>Form Submitted Successfully!</h1>
		<p className='successPage_subtitle'>Thank you for submitting the form. We will get back to you shortly.</p>
    {/* <Button variant='contained' onClick={() => window.close()}>Close</Button> */}
	</div>
  )
}
