import React, {useEffect, useState} from 'react'
import PublicViewForm from '../component/PublicViewForm'
import { getFormSchema } from '../Service'

export default function Forms() {
    const [formSChemaData, setFormSchemaData] = useState(null)
    const params = new URLSearchParams(window.location.search).get('id')
    useEffect(() => {
      getFormSchema(params).then((data) => {
        setFormSchemaData(data)
      })
    }, [])
    
  return (
    <div className='flex justify-center md:pt-40 sm:pt-20'>
        <div className='md:w-8/12 sm:w-full'>
            <PublicViewForm data={formSChemaData} />
        </div>
    </div>
  )
}
