import axios from "axios";

const checkNodeEnvNextGen = (prodUrl, devUrl) => {
    let url = '';
    // if(process.env.NODE_ENV === nodeEnv.production) url = prodUrl;
    if(process.env.REACT_APP_ENV === "local") url = devUrl;
    else if(process.env.REACT_APP_ENV === "staging") url = devUrl
    else url = prodUrl;
    return url;
}

const nextgenUrl = {
    live: "https://apix.churchpad.com/nextgen",
    staging: "https://apps1.churchpad.com/nextgen",
};

export const getFormSchema = async (id) => {
    try {
        const url = checkNodeEnvNextGen(`${nextgenUrl.live}/api/formSchemas/${id}`, `${nextgenUrl.staging}/api/formSchemas/${id}`)
        const response = await axios.get(url)
        if (response.status === 200) return response.data
    } catch (error) {
      throw new Error(error)  
    }
}

export const submitFormAnswer =async (id, payload, cb, errorCb) => {
    try {
        const url = checkNodeEnvNextGen(`${nextgenUrl.live}/api/formSubmissions/schema/${id}`,
            `${nextgenUrl.staging}/api/formSubmissions/schema/${id}`
        )
        const response = await axios.post(url, payload)

        if (response.status === 201) {
            return cb()
        }
        return errorCb()
    } catch (error) {
        errorCb()
        throw error
    }
}

  